@import 'variables.scss';
@import 'home.scss';

.post{
  padding-bottom: 100px;
}

.post-image{
  width:100%;
  max-height: 365px;
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

.post-image-side{
  width:100%;
  height:175;
}

.post-content{
  padding-top: 30px;
  padding-bottom: 30px
}


.page{
  position: relative;
  margin-top: 120px;
  background-color: #FFF;
  min-height: 600px;
}






.single-post{
  @extend .white-bg;

  &-image{
    width:100%;
    max-height: 555px;
  }

  &-header{
    margin-top:50px;
  }

  &-comments{
    @extend .white-bg;
    padding:70px;

    //more on _comments.scss
    
  }

  &-details{
    @extend .post-details;
    margin-bottom:50px;
  }

  &-content{
    padding-left: 230px;
    padding-right: 230px;

    img{
      width: 100%;
    }

    @include smallscreen{
      padding-left:20px;
      padding-right:20px;
    }

    h1,h2,h3,h4{
      //margin-top: 50px;
      margin-bottom: 20px;
    }

    .post-pagination{
      padding-top:80px;
    }
  }

  &-pagination{
    
    margin-top:50px;
    margin-bottom:50px;
    padding:50px;
    padding-top:20px;
    padding-bottom:20px;
    @extend .white-bg;

    .previous-post{
      height:150px;
      padding-top:50px;

      @include smallscreen{
        text-align: center;
      }

    }

    .next-post{
      text-align: right;
      padding-top:50px;
      height:150px;

      @include smallscreen{
        text-align: center;
      }
    }

     @media print {
       display: none;
     }
  }
}


.post-about-author{
  padding-top:70px;
}

.post-about-author-bio{ 
  h4{
    margin-top:0px;
    font-weight:normal;
    text-transform: uppercase;
  } 


}

.post-about-author-img{
  padding:40px;
  padding-top:0px;

  img{
    width:100%;
  }
}


