footer{
  background-color:#000;

  @media print {
    display:none; 
  }

}

.banner-footer{
  filter: invert(100%); 
  width:150px
}